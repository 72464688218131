/**

 * Pinka functions and definitions.

 */

(function ( $ ) {
	'use strict'

	$( function () {

		//= modules/load-more.js
		//= modules/menu-responsive.js
		//= modules/odometer.js
		//= modules/scroll_page_to_element.js
		//= modules/slider.js
		//= modules/slider-games-details.js
		
	} );

})( jQuery )